import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  Typography,
  Box,
  Paper,
  TableCell,
  Button,
  Grid,
  CircularProgress,
  Hidden,
  Alert
} from '@mui/material';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../actions';
import BasePageContent from '../../utils/BasePageContent';
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import Paginator from '../../Paginator';
import { navigate } from 'gatsby';
import StyledTableCell from '../../utils/StyledTableCell';
import SettlementStatusChip from '../../utils/SettlementStatusChip';
import formatAmount from '../../utils/formatAmount';
import { styled } from '@mui/material/styles';

const PREFIX = "SettlementDetails";
const classes = {
  paper: `${PREFIX}-paper`,
  pointer: `${PREFIX}-pointer`,
  titleBox: `${PREFIX}-titleBox`,
  title: `${PREFIX}-title`,
  descColumn: `${PREFIX}-descColumn`,
  gridItem: `${PREFIX}-gridItem`,
}
const Root = styled('div')(({theme}) => ({
  [`& .${classes.paper}`]: {
    height: '100%',
    border: 'none',
  },
  [`& .${classes.pointer}`]: {
    cursor: 'pointer',
  },
  [`& .${classes.titleBox}`]: {
    background: theme.palette.grey[100],
    color: '#000',
    fontWeight: 'bolder',
    padding: theme.spacing(2),
  },
  [`& .${classes.title}`]: {
    fontWeight: 'bold',
  },
  [`& .${classes.descColumn}`]: {
    maxWidth: '250px',
    wordWrap: 'break-word',
  },
  [`& .${classes.gridItem}`]: {
    borderTop: '1px solid #e3e3e3',
  },
}));

const mapStateToProps = state => ({
  settlement: state.getSettlement,
  report: state.getSettlementReport,
});

class SettlementDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      settlement: null,
      settlementError: null,
      ordersError: null,
    };
  }

  componentDidMount() {
    this.props.actions.getSettlement(this.props.code);
  }

  componentDidUpdate(prevProps) {
    const { settlement, report } = this.props;

    if (settlement !== prevProps.settlement) {
      if (settlement.reason) {
        this.setState({ settlementError: settlement.reason, loading: false });
      } else {
        this.setState({ settlement: settlement.data, loading: false });
      }
    }

    if (report !== prevProps.report) {
      this.downloadCsvReport(report);
    }
  }

  getReport = () => {
    const { code } = this.state.settlement;
    this.props.actions.getSettlementReport(code);
  };

  downloadCsvReport = report => {
    const blob = new Blob([report], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `rozliczenie_${this.props.code}.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  renderDetailsCard() {
    const s = this.state.settlement;
    const details = {
      Status: <SettlementStatusChip status={s.status} />,
      Opłata: formatAmount(s.feeAmount, s.currency),
      Utworzono: s.createdAt,
      Zaktualizowano: s.updatedAt,
    };

    const isClosed = !!s.closedAt;
    if (isClosed) {
      details['Zamknięto'] = s.closedAt;
    }

    return (
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box textAlign='right' className='amount'>
              {formatAmount(s.amount, s.currency)}
            </Box>
          </Grid>
          {Object.entries(details).map(([key, value]) => (
            <>
              <Grid item xs={6} className={classes.gridItem}>
                <Box textAlign='left'>{key}</Box>
              </Grid>
              <Grid item xs={6} className={classes.gridItem}>
                <Box textAlign='right'>{value}</Box>
              </Grid>
            </>
          ))}
          {isClosed && (
            <Grid item xs={12}>
              <Box textAlign='right'>
                <Button
                  rel='noreferrer'
                  // href={i.downloadUrl}
                  onClick={this.getReport}
                  target='_blank'
                  // component='a'
                  variant='contained'
                  disableElevation
                  color='primary'
                  endIcon={<GetAppRoundedIcon />}
                >
                  Pobierz raport (CSV)
                </Button>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    );
  }

  renderOrdersTable(orders) {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>Kod zlecenia</StyledTableCell>
            <StyledTableCell>Utworzono</StyledTableCell>
            <Hidden mdDown>
              <StyledTableCell>Opis</StyledTableCell>
            </Hidden>
            <StyledTableCell>Kwota</StyledTableCell>
            <StyledTableCell>Opłata</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map(({ data }) => (
            <TableRow
              key={data.code}
              hover
              className={classes.pointer}
              onClick={() => navigate(`/orders/${data.code}`)}
            >
              <TableCell>{data.code}</TableCell>
              <TableCell>{data.createdAt}</TableCell>
              <Hidden mdDown>
                <TableCell className={classes.descColumn}>
                  {data.description || (
                    <i style={{ opacity: 0.6 }}>brak opisu</i>
                  )}
                </TableCell>
              </Hidden>
              <TableCell>
                {formatAmount(data.amount, data.currency)}
              </TableCell>
              <TableCell>
                {formatAmount(data.fees[0].amount, data.fees[0].currency)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  renderOrders() {
    const { code } = this.props;
    return (
      <Paginator
        resource={this.props.orders}
        getResource={params =>
          this.props.actions.getOrdersForInvoice(code, params)
        }
        render={(orders, paginationFooter, loading, error) => (
          <Box py={6}>
            <Paper variant='outlined' className={classes.paper}>
              <Box textAlign='center'>
                <Typography variant='h5' paragraph>
                  Lista zleceń
                </Typography>
              </Box>
            </Paper>
            {loading ? (
              <Box textAlign='center'>
                <CircularProgress loading />
              </Box>
            ) : (
              <>
                {error ? (
                  <Alert severity='error'>{error}</Alert>
                ) : (
                  <TableContainer>
                    {this.renderOrdersTable(orders)}
                    <Grid container justifyContent='center' alignItems='center'>
                      <Grid item>
                        <Box py={2}>{paginationFooter()}</Box>
                      </Grid>
                    </Grid>
                  </TableContainer>
                )}
              </>
            )}
          </Box>
        )}
      />
    );
  }

  renderDetails() {
    const { code } = this.props;
    return (
      <Paper variant='outlined'>
        <Box textAlign='center' className={classes.titleBox}>
          <Typography variant='body1' className={classes.title}>
            Rozliczenie #{code}
          </Typography>
        </Box>
        {this.renderDetailsCard()}
      </Paper>
    );
  }

  render() {
    const { loading, settlement, settlementError } = this.state;
    return (
      <Root>
        <BasePageContent loading={loading}>
        {!loading && (
          <>
            {settlement ? (
              <>
                {this.renderDetails()}
              </>
            ) : (
              <Alert severity='error'>{settlementError}</Alert>
            )}
          </>
        )}
        </BasePageContent>
      </Root>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettlementDetails);
