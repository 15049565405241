import React from 'react';
import Layout from '../../../../templates/layout';
import SettlementDetails from '../../../../components/user/settlements/SettlementDetails';

const SettlementPage = props => (
  <Layout>
    <SettlementDetails code={props.code} location={props.location} />
  </Layout>
);


export const Head = () => <title>Rozliczenia | Zapłatomat</title>;

export default SettlementPage;
